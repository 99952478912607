import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/owenbean/ems/ems_web/src/components/ApiDocumentationLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Sync Truck`}</h1>
    <h2>{`POST `}<inlineCode parentName="h2">{`/v1/ems/truck/sync/{truck_id}`}</inlineCode></h2>
    <p>{`Register a truck as being synced to the latest from the device. When updating a truck record on a device based on the cloud data, send this HTTP request to let the backend know the record is in sync with the device.`}</p>
    <h3>{`Request`}</h3>
    <pre><code parentName="pre" {...{}}>{`"Authorization": "Bearer <Token>"
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Path Parameters`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`truck_id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The ID of the truck.`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Body`}</h4>
    <pre><code parentName="pre" {...{}}>{`{
    "device_id": [string] UUID device id
}
`}</code></pre>
    <h3>{`Response `}<inlineCode parentName="h3">{`200`}</inlineCode></h3>
    <p>{`Truck record has been set as sync up for the device.`}</p>
    <h4>{`Body`}</h4>
    <pre><code parentName="pre" {...{}}>{`{
    "device_id": [string] UUID device id
}
`}</code></pre>
    <h3>{`Response `}<inlineCode parentName="h3">{`201`}</inlineCode></h3>
    <p>{`Truck record has made a new tracking record of the sync up for the device.`}</p>
    <h4>{`Body`}</h4>
    <pre><code parentName="pre" {...{}}>{`{
    "device_id": [string] UUID device id
}
`}</code></pre>
    <h3>{`Response `}<inlineCode parentName="h3">{`401`}</inlineCode></h3>
    <p>{`Unauthorizated for the user from bearer token.`}</p>
    <h4>{`Body`}</h4>
    <pre><code parentName="pre" {...{}}>{`{
    "Error": "Unauthorized",
    "message": [string] Authorization issue
}
`}</code></pre>
    <h4>{`Messages`}</h4>
    <ul>
      <li parentName="ul">{`You are not authorized to access this resource`}
        <blockquote parentName="li">
          <p parentName="blockquote">{`The token from Firebase is invalid`}</p>
        </blockquote>
      </li>
      <li parentName="ul">{`Authorization header is missing`}
        <blockquote parentName="li">
          <p parentName="blockquote">{`There is no "Authorization" in the header request.`}</p>
        </blockquote>
      </li>
      <li parentName="ul">{`Invalid authorization header format`}
        <blockquote parentName="li">
          <p parentName="blockquote">{`The "Authorization" header does not have "Bearer Token" format.`}</p>
        </blockquote>
      </li>
    </ul>
    <h3>{`Response `}<inlineCode parentName="h3">{`404`}</inlineCode></h3>
    <p>{`The device ID or truck record could was not found.`}</p>
    <h4>{`Body`}</h4>
    <pre><code parentName="pre" {...{}}>{`{}
`}</code></pre>
    <h3>{`Response `}<inlineCode parentName="h3">{`500`}</inlineCode></h3>
    <p>{`Internal error with the server.`}</p>
    <h4>{`Body`}</h4>
    <pre><code parentName="pre" {...{}}>{`{
    "error": "Internal Server Error",
    "message": "Internal Server Error"
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      